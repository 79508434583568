import { ButtonLink } from '@/components/common/ButtonLink';
import { SVG } from '@/components/common/SVG';

export const DiscordCTABlock = () => (
  <section className="mx-auto max-w-screen-lg">
    <div className="mx-auto mt-4 w-11/12 md:py-12 md:mt-0 lg:py-12 lg:mt-0 lg:w-2/3">
      <hr className="text-white md:border-t-2 lg:border-t md:text-emint-gray-100 lg:text-emint-gray-100" />
    </div>
    <div className="pt-10 mx-auto w-11/12 text-white md:pt-0 lg:pt-0">
      <div className="space-y-5">
        <div className="px-4 text-center md:px-24 lg:px-24">
          <h4 className="text-2xl text-white">
            Hang with the Entertainmint{' '}
            <span className="block md:inline lg:inline">
              team, ask questions,{' '}
            </span>
            <span className="md:block lg:inline">
              and get the latest updates!
            </span>
          </h4>
        </div>
        <div className="flex justify-center">
          <a href="https://discord.gg/e7ue9RdW7a">
            <SVG
              type="discord"
              color="text-emint-purple"
              width="42"
              height="30"
            />
          </a>
        </div>
        <div className="flex justify-center">
          <ButtonLink
            href="https://discord.gg/e7ue9RdW7a"
            color="bg-emint-purple"
            textColor="text-white"
            textSize="lg:text-base"
            text="Join Our Discord"
          />
        </div>
      </div>
    </div>
    <div className="pt-10 pb-8 mx-auto w-11/12 md:py-12 lg:py-12 lg:w-2/3">
      <hr className="text-white md:border-t-2 lg:border-t md:text-emint-gray-100 lg:text-emint-gray-100" />
    </div>
  </section>
);
