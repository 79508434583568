import React, { FC } from 'react';
import Link from 'next/link';

interface ButtonLinkProps {
  text: string;
  href: string;
  color?: string; //In the future we will have a set number of variants based on a styleguide
  textSize?: string;
  textColor: string;
}

export const ButtonLink: FC<ButtonLinkProps> = ({
  text,
  href,
  color,
  textSize,
  textColor,
}) => (
  <Link href={href}>
    <a
      className={`px-3 py-2
        md:px-10
        lg:px-6 lg:py-3
        ${textColor} ${color} ${textSize || 'text-sm'}
        border border-hidden rounded-3xl`}
    >
      {text}
    </a>
  </Link>
);
