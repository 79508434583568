import Link from 'next/link';
import {
  ABOUT,
  ADVISORS,
  CONTACT,
  FAQ,
  PRESS,
  TEAM,
  PRIVACY,
  TERMS,
} from 'src/config/zendesk';

import { ButtonLink } from './common/ButtonLink';
import { SVG } from './common/SVG';
import {
  DiscordBadge,
  FBBadge,
  IGBadge,
  RedditBadge,
  TikTokBadge,
  TwitterBadge,
} from './SocialBadges';

// responsive
export const Footer = (): JSX.Element => (
  <section className="mx-auto max-w-screen-lg">
    <div className="mx-auto w-11/12 lg:w-2/3">
      <span className="flex justify-center pb-8">
        <SVG width={170} height={27} type="entertainmint" />
      </span>
      {location.pathname === '/' ? (
        <span className="flex justify-center pb-8">
          <ButtonLink
            href="https://open.spotify.com/show/3dzf8T6MFjttTvNe6GOLTC"
            color="bg-emint-purple"
            textColor="text-white"
            textSize="md:text-[14px] lg:text-lg"
            text="Check out our CEO's podcast"
          />
        </span>
      ) : (
        <></>
      )}
    </div>
    <div className="flex flex-col items-center space-y-6 md:flex-row md:justify-evenly md:space-y-0 lg:flex-row lg:justify-evenly lg:space-y-0">
      <div className="flex items-center space-x-4">
        <FBBadge username="entertainmintco" color="text-emint-gray-100" />
        <IGBadge username="entertainmintco" color="text-emint-gray-100" />
        <TwitterBadge username="entertainmintco" color="text-emint-gray-100" />
        <DiscordBadge username="e7ue9RdW7a" color="text-emint-gray-100" />
        <RedditBadge username="entertainmintco" color="text-emint-gray-100" />
        <TikTokBadge username="entertainmintco" color="text-emint-gray-100" />
      </div>
      <div className="flex items-center space-x-4 text-lg text-white">
        <p>Questions? Answers here:</p>
        <ButtonLink
          href={FAQ}
          color="bg-emint-purple"
          textColor="text-white"
          textSize="md:text-[14px] lg:text-lg"
          text="FAQs"
        />
      </div>
    </div>
    <div className="flex flex-row flex-wrap justify-center mt-4 space-x-8 text-white sm:text-sm md:text-lg">
      <Link href={ABOUT}>
        <a className="mt-4">About Us</a>
      </Link>
      <Link href={TEAM}>
        <a className="mt-4">Team</a>
      </Link>
      <Link href={ADVISORS}>
        <a className="mt-4">Advisors</a>
      </Link>
      <Link href={PRESS}>
        <a className="mt-4">Press/Media</a>
      </Link>
      <Link href={CONTACT}>
        <a className="mt-4">Contact Us</a>
      </Link>
      <Link href={PRIVACY}>
        <a className="mt-4">Privacy Policy</a>
      </Link>
      <Link href={TERMS}>
        <a className="mt-4">Terms of Use</a>
      </Link>
      {location.pathname === '/' ? (
        <></>
      ) : (
        <a
          className="mt-4"
          href="https://open.spotify.com/show/3dzf8T6MFjttTvNe6GOLTC"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check out our CEO&apos;s podcast
        </a>
      )}
    </div>
    <div className="pt-5 pb-28 text-xs text-center text-white">
      <p>
        ©2022-2023 Entertainmint, Inc. All Rights Reserved. Entertainmint™️ is
        used under license.
      </p>
    </div>
  </section>
);
