import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';
import { browserEnv } from 'src/config/browser-env-vars';
import { EmailForm } from './partials/forms/EmailForm';

interface Props {
  status: string;
  subscribe: (data: EmailFormFields) => void;
}

export const EmailInput = ({ status, subscribe }: Props) => {
  const callOutSpan = (
    <span className="hidden md:inline"> (let&apos;s go!)</span>
  );

  return (
    <div>
      <h4 className="pt-10 pb-6 text-2xl text-white">
        <span className="block md:inline lg:inline">
          Ready to join the fun?{' '}
        </span>
        <span className="block md:inline lg:inline">
          Enter your email to get started:
        </span>
      </h4>
      <div>
        <EmailForm
          status={status}
          subscribe={subscribe}
          buttonText="LFG!"
          borderColor="border-emint-purple"
          buttonColor="bg-emint-purple"
          buttonSpan={callOutSpan}
        />
      </div>
    </div>
  );
};

const EmailCaptureForm = () => {
  return (
    <MailchimpSubscribe
      url={browserEnv('NEXT_PUBLIC_MAILCHIMP_URL')}
      render={({ subscribe, status, message }) => {
        return <EmailInput status={status} subscribe={subscribe} />;
      }}
    />
  );
};

export default EmailCaptureForm;
