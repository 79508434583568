import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { EmailFormFields } from 'react-mailchimp-subscribe';
import { SuccessfulMintMessage } from './SuccessfulMintMessage';
import { Error } from './EmailError';

interface Props {
  status?: string;
  subscribe: (data: EmailFormFields) => void;
  placeholderText?: string;
  buttonText: string;
  borderColor?: string;
  buttonColor?: string;
  buttonTextColor?: string;
  textSize?: string;
  buttonSpan?: JSX.Element;
}

export const EmailForm = ({
  status,
  subscribe,
  placeholderText,
  buttonText,
  borderColor,
  buttonColor,
  textSize,
  buttonSpan,
}: Props) => {
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    subscribe({ EMAIL: email });
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleKeyboardSubmit = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  if (status === 'success') return <SuccessfulMintMessage />;
  return (
    <div>
      <div>
        <div className="flex justify-center mb-2">
          <input
            className={`pl-4 w-full h-14 italic text-left text-white bg-transparent rounded-l-md border-2 md:w-2/6 lg:w-2/6 lg:text-lg focus:outline-none placeholder:text-white ${borderColor}`}
            onChange={handleEmailChange}
            type="input"
            placeholder={placeholderText || 'Your email address'}
            onKeyDown={handleKeyboardSubmit}
          />
          <button
            type="submit"
            className={`py-3 px-10 h-14 ${
              textSize || 'text-xl md:text-2xl'
            } font-semibold rounded-r-md  ${buttonColor} text-emint-gray-500`}
            onClick={handleSubmit}
          >
            {buttonText}
            {buttonSpan}
          </button>
        </div>
        <Error status={status} />
      </div>
    </div>
  );
};
