import EmailCaptureForm from '@/components/EmailCaptureForm';

export const CTABlock = (): JSX.Element => (
  <section className="mx-auto max-w-screen-lg">
    <div className="mx-auto w-11/12 text-center md:w-full lg:w-full">
      <div className="mx-auto mt-0 md:w-11/12 lg:w-2/3">
        <hr className="md:border-t-2 lg:border-t text-emint-gray-100" />
      </div>
      <EmailCaptureForm />
    </div>
  </section>
);
