import { SVG } from '../../common/SVG';

export const SuccessfulMintMessage = () => {
  return (
    <div className="text-center">
      <div className="mt-10 mb-2">
        <p className="text-lg text-white">Thanks for joining in!</p>
        <p className="text-lg text-emint-mint">
          Check your inbox for something minty...
        </p>
      </div>
      <div className="flex justify-center">
        <div>
          <SVG type="emintLogo" width="42" height="30" />
        </div>
      </div>
    </div>
  );
};
